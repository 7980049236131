import request from './index.js'

//获取会议列表
export function getConference(data={}) {
  return request({
    url: "/api/conference/search",
    method: "GET",
    params: data
  })
}

//获取时间条件列表
export function getTime(data={}) {
  return request({
    url: "/api/time/search",
    method: "GET",
    params: data
  })
}

//获取时间条件简化列表
export function simpleTime(data={}) {
  return request({
    url: "/api/time/simple",
    method: "GET",
    params: data
  })
}

//获取假期列表
export function getDay(data={}) {
  return request({
    url: "/api/day/search",
    method: "GET",
    params: data
  })
}

//获取功能码列表
export function allFuncode(data={}) {
  return request({
    url: "/api/funcode/all",
    method: "GET",
    params: data
  })
}

//获取单个会议列表
export function findConference(data={}) {
  return request({
    url: "/api/conference/find",
    method: "GET",
    params: data
  })
}

//获取单个时间条件列表
export function findTime(data={}) {
  return request({
    url: "/api/time/find",
    method: "GET",
    params: data
  })
}

//获取单个假期列表
export function findDay(data={}) {
  return request({
    url: "/api/day/find",
    method: "GET",
    params: data
  })
}

//会议编辑
export function updateConference(data={}) {
  return request({
    url: "/api/conference/update",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//时间条件编辑
export function updateTime(data={}) {
  return request({
    url: "/api/time/update",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//假期编辑
export function updateDay(data={}) {
  return request({
    url: "/api/day/update",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}

//功能码编辑
export function batcheditFuncode(data={}) {
  return request({
    url: "/api/funcode/batchedit",
    method: "POST",
    data
  })
}

//会议添加
export function addConference(data={}) {
  return request({
    url: "/api/conference/add",
    method: "POST",
    data
  })
}


//时间条件添加
export function addTime(data={}) {
  return request({
    url: "/api/time/add",
    method: "POST",
    data
  })
}

//假期添加
export function addDay(data={}) {
  return request({
    url: "/api/day/add",
    method: "POST",
    data
  })
}

//会议删除
export function deleteConference(data={}) {
  return request({
    url: "/api/conference/delete",
    method: "DELETE",
    data
  })
}

//时间条件删除
export function deleteTime(data={}) {
  return request({
    url: "/api/time/delete",
    method: "DELETE",
    data
  })
}

//假期删除
export function deleteDay(data={}) {
  return request({
    url: "/api/day/delete",
    method: "DELETE",
    data
  })
}

//时间条件批量删除
export function batchdelTime(data={}) {
  return request({
    url: "/api/time/batchdel",
    method: "DELETE",
    data
  })
}

//假期批量删除
export function batchdelDay(data={}) {
  return request({
    url: "/api/day/batchdel",
    method: "DELETE",
    data
  })
}