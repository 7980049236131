<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div>
    <el-card shadow="hover">
      <h3 class="title mb15">{{ $t('功能码') }}</h3>
      <el-form :model="ruleForm" label-width="120px" class="demo-ruleForm" size="small" label-position="top">
        <el-row :gutter="35">
          <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="功能码输入超时时间(ms)">
              <el-input v-model="ruleForm.name" placeholder="时间(ms)" clearable></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="margin-bottom:20px;">
            <!--            <el-row :gutter="35">-->
            <!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">-->
            <!--              <h3>{{ $t('录音') }}</h3>-->
            <!--            </el-col>-->
            <!--            <codeOption :title="$t('一键录音')" v-model:status="ruleForm[1].status" v-model:code="ruleForm[1].code"/>-->
            <!--            <codeOption :title="$t('全局录音切换')" v-model:status="ruleForm[2].status" v-model:code="ruleForm[2].code"/>-->
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                    style="margin-bottom:20px;background-color: #d1d1d1;width:100%;">
              <h4 class="u-funcode-item-title">{{ $t('语音信箱') }}</h4>
            </el-col>
            <codeOption :title="$t('收听语音信箱')" v-model:status="ruleForm[5].status"
                        v-model:code="ruleForm[5].code"/>
            <!-- <codeOption :title="'分机语音信箱'" v-model:status="ruleForm[6].status" v-model:code="ruleForm[6].code" />
            <codeOption :title="'语音信箱菜单'" v-model:status="ruleForm[7].status" v-model:code="ruleForm[7].code" /> -->
            <!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">-->
            <!--              <h3>{{ $t('通话转接') }}</h3>-->
            <!--            </el-col>-->
            <!--            <codeOption :title="$t('盲转接')" v-model:status="ruleForm[11].status" v-model:code="ruleForm[11].code"/>-->
            <!--            <codeOption :title="$t('指定转接')" v-model:status="ruleForm[12].status" v-model:code="ruleForm[12].code"/>-->
            <!--            <codeOption :title="$t('指定转接时长')" v-model:status="ruleForm[13].status" v-model:code="ruleForm[13].code"/>-->
            <!--            <codeOption :title="$t('转移按键超时时间')" v-model:status="ruleForm[14].status" v-model:code="ruleForm[14].code"/>-->

            <!--            </el-row>-->
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <!--            <el-row :gutter="35">-->
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                    style="margin-bottom:20px;background-color: #d1d1d1;width:100%;">
              <h4 class="u-funcode-item-title">{{ $t('截答') }}</h4>
            </el-col>
            <codeOption :title="$t('同组截答')" v-model:status="ruleForm[17].status"
                        v-model:code="ruleForm[17].code"/>
            <codeOption :title="$t('指定截答')" v-model:status="ruleForm[18].status"
                        v-model:code="ruleForm[18].code"/>
            <!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">-->
            <!--              <h3>{{ $t('呼叫转移') }}</h3>-->
            <!--            </el-col>-->
            <!--            <codeOption :title="$t('启用无条件呼叫转移')" v-model:status="ruleForm[3].status" v-model:code="ruleForm[3].code"/>-->
            <!--            <codeOption :title="$t('取消无条件呼叫转移')" v-model:status="ruleForm[4].status" v-model:code="ruleForm[4].code"/>-->
            <!--            <codeOption :title="$t('启用遇忙转移')" v-model:status="ruleForm[8].status" v-model:code="ruleForm[8].code"/>-->
            <!--            <codeOption :title="$t('取消遇忙转移')" v-model:status="ruleForm[9].status" v-model:code="ruleForm[9].code"/>-->
            <!--            <codeOption :title="$t('启用无应答转移')" v-model:status="ruleForm[10].status" v-model:code="ruleForm[10].code"/>-->
            <!--            <codeOption :title="$t('取消无应答转移')" v-model:status="ruleForm[19].status" v-model:code="ruleForm[19].code"/>-->
            <!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">-->
            <!--              <h3>{{ $t('免打扰') }}</h3>-->
            <!--            </el-col>-->
            <!--            <codeOption :title="$t('启用免打扰')" v-model:status="ruleForm[15].status" v-model:code="ruleForm[15].code"/>-->
            <!--            <codeOption :title="$t('取消免打扰')" v-model:status="ruleForm[16].status" v-model:code="ruleForm[16].code"/>-->
            <!--            </el-row>-->
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="margin-bottom:20px;">
            <!--            <el-row :gutter="35">-->
            <!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">-->
            <!--              <h3>{{ $t('录音') }}</h3>-->
            <!--            </el-col>-->
            <!--            <codeOption :title="$t('一键录音')" v-model:status="ruleForm[1].status" v-model:code="ruleForm[1].code"/>-->
            <!--            <codeOption :title="$t('全局录音切换')" v-model:status="ruleForm[2].status" v-model:code="ruleForm[2].code"/>-->
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                    style="margin-bottom:20px;background-color: #d1d1d1;width:100%;">
              <h4 class="u-funcode-item-title">{{ $t('呼叫转移') }}</h4>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item :label="$t('无条件转移')" prop="dn">
                <el-switch v-model="ruleForm[7].status" :active-value="1" :inactive-value="0"></el-switch>
                <el-input v-model="ruleForm[7].code" maxlength="6"
                          onkeyup="value=value.replace(/[^*\d]/g,'')" clearable>
                  <template v-slot:prepend>{{$t('启用')}}</template>
                </el-input>
                <el-input v-model="ruleForm[8].code" maxlength="6"
                          onkeyup="value=value.replace(/[^*\d]/g,'')" clearable>
                  <template v-slot:prepend>{{ $t('取消')}}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item :label="$t('无应答转移')" prop="dn">
                <el-switch v-model="ruleForm[9].status" :active-value="1" :inactive-value="0"></el-switch>
                <el-input v-model="ruleForm[9].code" maxlength="6"
                          onkeyup="value=value.replace(/[^*\d]/g,'')" clearable>
                  <template v-slot:prepend>{{$t('启用')}}</template>
                </el-input>
                <el-input v-model="ruleForm[10].code" maxlength="6"
                          onkeyup="value=value.replace(/[^*\d]/g,'')" clearable>
                  <template v-slot:prepend>{{$t('取消')}}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item :label="$t('遇忙转移')" prop="dn">
                <el-switch v-model="ruleForm[11].status" :active-value="1" :inactive-value="0"></el-switch>
                <el-input v-model="ruleForm[11].code" maxlength="6"
                          onkeyup="value=value.replace(/[^*\d]/g,'')" clearable>
                  <template v-slot:prepend>{{$t('启用')}}</template>
                </el-input>
                <el-input v-model="ruleForm[12].code" maxlength="6"
                          onkeyup="value=value.replace(/[^*\d]/g,'')" clearable>
                  <template v-slot:prepend>{{$t('取消')}}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <!-- <codeOption :title="'分机语音信箱'" v-model:status="ruleForm[6].status" v-model:code="ruleForm[6].code" />
            <codeOption :title="'语音信箱菜单'" v-model:status="ruleForm[7].status" v-model:code="ruleForm[7].code" /> -->
            <!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">-->
            <!--              <h3>{{ $t('通话转接') }}</h3>-->
            <!--            </el-col>-->
            <!--            <codeOption :title="$t('盲转接')" v-model:status="ruleForm[11].status" v-model:code="ruleForm[11].code"/>-->
            <!--            <codeOption :title="$t('指定转接')" v-model:status="ruleForm[12].status" v-model:code="ruleForm[12].code"/>-->
            <!--            <codeOption :title="$t('指定转接时长')" v-model:status="ruleForm[13].status" v-model:code="ruleForm[13].code"/>-->
            <!--            <codeOption :title="$t('转移按键超时时间')" v-model:status="ruleForm[14].status" v-model:code="ruleForm[14].code"/>-->

            <!--            </el-row>-->
          </el-col>
        </el-row>
        <el-affix position="bottom" :offset="10">
          <el-button size="small" type="primary" @click="submitForm">{{ $t('保存') }}</el-button>
          <el-button size="small" @click="resetForm">{{ $t('取消') }}</el-button>
        </el-affix>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  nextTick
} from 'vue'
import {useI18n} from "vue-i18n";
import codeOption from './code-option'
import {
  allFuncode,
  batcheditFuncode
} from '@/http/setUp.api.js'
import {
  ElNotification
} from 'element-plus';

export default {
  name: 'functionCode',
  components: {
    codeOption
  },
  setup() {
    const {t}   = useI18n()
    const state = reactive({
      sysTitle: "",
      ruleForm: [
        {
          code: '',
          status: 0,
          type: '0',
        },
        {
          code: '',
          status: 0,
          type: '1',
        },
        {
          code: '',
          status: 0,
          type: '2',
        },
        {
          code: '',
          status: 0,
          type: '3',
        },
        {
          code: '',
          status: 0,
          type: '4',
        },
        {
          code: '',
          status: 0,
          type: '5',
        },
        {
          code: '',
          status: 0,
          type: '6',
        },
        {
          code: '',
          status: 0,
          type: '7',
        },
        {
          code: '',
          status: 0,
          type: '8',
        },
        {
          code: '',
          status: 0,
          type: '9',
        },
        {
          code: '',
          status: 0,
          type: '10',
        },
        {
          code: '',
          status: 0,
          type: '11',
        },
        {
          code: '',
          status: 0,
          type: '12',
        },
        {
          code: '',
          status: 0,
          type: '13',
        },
        {
          code: '',
          status: 0,
          type: '14',
        },
        {
          code: '',
          status: 0,
          type: '15',
        },
        {
          code: '',
          status: 0,
          type: '16',
        },
        {
          code: '',
          status: 0,
          type: '17',
        },
        {
          code: '',
          status: 0,
          type: '18',
        },
        {
          code: '',
          status: 0,
          type: '19',
        },
      ],
      err: [1, 2, 3, 4, 5, 6, 7, 8],
      tableData: {
        name: '',
        number: '',
      },
      listMenu: {
        title: '一键录音',
        checked1: false
      }
    });
    nextTick(() => {
      blackList(state);
    })

    const isEmptyObject = (obj) => {
      for (var key in obj) {
        return false;
      }
      return true;
    }


    const blackList = (state) => {
      allFuncode().then(res => {
        if (res.code == 200) {
          if (res.data != null && !isEmptyObject(res.data)) {
            state.ruleForm = res.data;
          }
          state.sysTitle = res.tag;
        } else {
          ElNotification({
            title: '失败',
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    function submitForm() {
      const funcode = Array();
      for (let i in state.ruleForm) {
        if (state.ruleForm[i].status == 1 && state.ruleForm[i].code == '') {
          ElNotification({
            title: t('失败'),
            message: t('请设置功能码'),
            type: 'error',
            duration: 2000,
          });
          return;
        }
        funcode.push(state.ruleForm[i])
      }
      const params = {
        funcodes: funcode,
      };
      batcheditFuncode(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("编辑成功"),
            type: 'success',
            duration: 2000,
          });
          // state.isShowDialog= false;
          // context.emit("print");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    //取消
    function resetForm() {
      blackList(state);
    }

    return {
      ...toRefs(state),
      submitForm,
      resetForm
    }
  }
}
</script>
<style scoped>
.el-affix :deep(.el-affix--fixed) {
  display: flex;
  justify-content: center;
  background: white;
}

.el-button--small {
  margin: 0 10px;
}

:deep(.el-input-group__prepend) {
  background-color: #f0f0f0;
}

:deep(.u-funcode-item-title) {
  color: #4a4a4a;
}

:deep(.title) {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

:deep(.el-form-item__content) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:deep(.el-form-item__content .el-switch) {
  margin-right: 8px;
}

:deep(.el-form-item__content .el-input) {
  margin-right: 8px;
}
</style>
