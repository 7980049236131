<template>
  <div class="mb15" style="padding:0px 30px;">
    <!--    <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22" class="mb15">-->
    <el-input
        style="width: 100%;"
        v-model="codeData"
        :disabled="checked == '0'"
        maxlength="6" onkeyup="value=value.replace(/[^*\d]/g,'')" clearable
    >
      <template #prepend>
        <el-checkbox v-model="checked" :label="title" :true-label='1' :false-label='0'>
        </el-checkbox>
      </template>
    </el-input>
    <!--    </el-col>-->
  </div>
</template>
<script>
import {
  ref,
  watch,
  onMounted,
} from 'vue'

export default {
  name: 'codeOption',
  props: {
    title: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
  },
  emits: ['update:status', 'update:code'],
  setup(props, {
    emit
  }) {
    const checked  = ref(props.status);
    const codeData = ref(props.code);
    watch(() => props.code, (newVal) => {
      codeData.value = newVal;
    })
    watch(() => props.status, (newVal) => {
      checked.value = newVal;
    })
    watch(
        () => codeData.value,
        (data) => emit('update:code', data),
    ) // 监听富文本输入值变动
    watch(
        () => checked.value,
        (data) => emit('update:status', data),
    ) // 监听富文本输入值变动
    watch(() => checked.value, (newVal) => {
      if (newVal == 0) {
        codeData.value = '';
      }
    })
    onMounted(() => {
      empty()
    })

    function empty() {
      if (checked.value == '0') {
        codeData.value = '';
      }
    }

    return {
      checked,
      codeData,
      empty
    }
  }
}
</script>
